import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as data from '../assets/data.json';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  aliApi = 'https://onon-guanwang.oss-cn-beijing.aliyuncs.com/';
  newsApi = 'https://s_www1.onon.cn/GetNews';

  data;

  constructor(private http: HttpClient) { 
    this.data = (data as any).default;
  }

  GetNoAccessService() {
    return this.data.noAccessService;
  }
  
  GetServiceList() {
    return this.data.service;
  }

  GetSliderList() {
    return this.data.sliderList;
  }

  GetSmallSliderList() {
    return this.data.smallSliderList;
  }
  
  GetProduct(){
    return this.data.productSliderList;
  }

  GetProductList() {
    return this.data.ProductList;
  }

  GetproductList() {
    return this.data.productList;
  }

  GetWechatAppletList() {
    return this.data.wechatAppletList;
  }

  GetSuccessList() {
    return this.data.successList;
  }

  GetNews(): Observable<any> {
    return this.http.get(this.newsApi);
  }

  GetCooperation() {
    return this.data.cooperation;
  }

  GetQualification() {
    return this.data.qualification;
  }

  GetInternetService() {
    return this.data.internetService;
  }

  GetOffice() {
    return this.data.office;
  }

  GetlanguageIndustry() {
    return this.data.languageIndustry;
  }

  AddNotes() {
    var py =  document.querySelectorAll('.pinyin')
    py.forEach((item,index) => {
      py[index].innerHTML = py[index].innerHTML.replace('斡仑','<ruby>斡<rt>wò</rt>仑<rt>lún</rt></ruby>');
    });
  }
}
